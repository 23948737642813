.register-account-container {
  width: 100%;
  background: rgba(255, 255, 255, 0.92);
  display: flex;
  flex-direction: column;
  align-items: center;

  .register-account-title {
    color: #60A9A6;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 54px;
  }

  .register-account-form-container {
    margin-bottom: 40px;
    width: 100%;

    .register-account-form-row {
      display: flex;
      gap: 0 40px;

      .ant-form-item {
        flex: 1;
      }
    }

    .ant-form-item {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-form-item-label {
        padding-bottom: 12px;
        
        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #666666;
        }

        .ant-form-item-required {
          &:after {
            content: '*';
            display: block;
            color: #EE1D52;
          }
        }
      }

      .ant-input {
        height: 45px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        border-radius: 12px;
        font-size: 18px;
      }

      .ant-select {
        .ant-select-selector {
          height: 45px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          border-radius: 12px;
          font-size: 18px;

          .ant-select-selection-search-input {
            height: 100%;
          }

          .ant-select-selection-placeholder {
            height: 100%;
            line-height: 43px;
          }

          .ant-select-selection-item {
            height: 100%;
            line-height: 43px;
          }
        }
      }

      .ant-select-dropdown {
        .ant-select-item-option,
        .ant-select-item  {
          height: 100%;
          line-height: 43px;
          font-size: 18px;
        }
      }
    }
  }

  .register-account-tip-container {
    color: #393939;
    font-feature-settings: 'salt' on;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
    padding: 32px 0;
    margin-bottom: 40px;

    .register-account-tip-link {
      color: #60A9A6;
      font-weight: 700;
      text-decoration-line: underline;
      cursor: pointer;
      user-select: none;
    }
  }

  .register-account-btn {
    display: flex;
    height: 50px;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 20px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
