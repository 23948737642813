.new-detail-container {
  width: 100%;
  padding: 0 100px;
  
  .new-detail-header-container {
    .content-header-title {
      padding: 0 235px;
    }
  }
  
  .new-detail-content-container {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 60px;
    
    .new-detail-content-item-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      
      .new-detail-content-item-title {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 180%; /* 28.8px */
      }
      
      .new-detail-content-item-des {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
      }
    }
  }

  .new-detail-related-blogs-container {
    padding: 30px 0 100px;
    display: flex;
    flex-direction: column;
    gap: 80px;

    .new-detail-related-blogs-title {
      color: #60A9A6;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }

    .new-detail-related-blogs-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 100px 80px;

      .new-detail-related-blogs-item-container {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
