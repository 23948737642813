.layout-container {
  min-height: 100vh;
  min-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  
  .layout-header-container {
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: #FFFFFF;
  }

  .app-content-container {
    width: 1440px;
    padding-top: 118px;
  }
}
