.our-products-container {
  width: 100%;
  padding: 0 100px;

  .our-products-header-container {
    .content-header-title {
      padding: 0 157px;
    }
  }
  
  .our-products-content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .our-products-content-filter-container {
      display: flex;
      flex-direction: column;
      width: 292px;
      padding: 10px;
      gap: 30px;
      position: absolute;
      height: calc(100vh - 118px);
      &.fixed {
        position: fixed;
        top: 118px;
      }
      &.stickBottom {
        position: absolute;
        bottom: 0px;
      }
      .our-products-content-filter-top-container {
        width: calc(292px - 20px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #000;
        position: absolute;
        
        .our-products-content-filter-top-title {
          color: #393939;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        
        .our-products-content-filter-top-clear-filter {
          color: #DA5864;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          user-select: none;
        }
      }
      
      .our-products-content-filter-menu-container {
        margin-top: 70px;
        overflow-y: scroll;
      }
    }
    
    .our-products-content-right-container {
      flex: 1;
      padding: 10px;
      margin-left: 340px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      min-height: calc(100vh - 118px);
      
      .our-products-content-right-title-container {
        display: flex;
        align-items: center;
        padding: 10px 0;
        gap: 10px;
        color: #393939;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        z-index: 1;
        background-color: #FFFFFF;
        width: 100%;
        &.fixed {
          position: fixed;
          top: 118px;
        }
        &.stickTop {
          position: absolute;
          top: 0px;
        }
        
        .our-products-content-right-title-icon-container {
          width: 24px;
          height: 24px;
          
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      
      .our-products-content-right-search-sort-container {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 94px;
        
        .our-products-content-right-search-container {
          width: 724px;
          height: 45px;
          padding: 4px 20px;
          border-radius: 50px;
          background-color: #F6F6F6;
          border: none;
          
          .ant-input {
            background-color: #F6F6F6;
            
            &::placeholder {
              color: rgba(57, 57, 57, 0.50);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
          }
          
          .our-products-content-right-search-icon-container {
            width: 24px;
            height: 24px;
            cursor: pointer;
            user-select: none;
            
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
        
        .our-products-content-right-sort-container {
          display: flex;
          width: 134px;
          height: 45px;
          padding: 10px 40px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 20px;
          background: #F6F6F6;
          cursor: pointer;
          user-select: none;
        }
      }
      
      .our-products-content-right-product-group-container {

        .our-products-content-right-product-group-list {
          display: flex;
          flex-wrap: wrap;
          gap: 20px 52px;
          margin-bottom: 50px;

          .our-products-content-right-product-group-item-container {
            width: 250px;
          }
        }

        .our-products-content-right-product-group-btn {
          color: #DA5864;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px; /* 130% */
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}
