.footer-container {
  width: 100%;
  background-color: #FBFBFB;
  
  .footer-link {
    text-decoration-line: underline;
    cursor: pointer;
    user-select: none;
  }
  
  .footer-top-container {
    width: 1240px;
    height: 676px;
    padding: 80px 0;
    margin: 0 auto;
    
    .footer-top-content-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      
      .footer-top-content-title {
        color: #60A9A6;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      
      .footer-top-content-left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .footer-top-content-left-logo-container {
          width: 381px;
          height: 195px;
          
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        
        .footer-top-content-left-des {
          width: 450px;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 187.5% */
        }
        
        .footer-top-content-link-container {
          display: flex;
          align-items: center;
          gap: 50px;
        }
        
        .footer-top-content-left-get-in-touch-container {
          display: flex;
          align-items: center;
          
          .footer-top-content-left-get-in-touch-list {
            display: flex;
            align-items: center;
            padding: 10px 0;
            gap: 20px;
            margin-left: 50px;
            
            .footer-top-content-left-get-in-touch-item-container {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }
      
      .footer-top-content-right-container {
        width: 450px;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .footer-top-content-right-working-hours-container {
          display: flex;
          flex-direction: column;
          gap: 30px;
          
          .footer-top-content-right-working-hours-item-container {
            color: #000;
            font-weight: 700;
            
            span {
              font-weight: 400;
            }
          }
        }
        
        .footer-top-content-right-contact-us-container {
          display: flex;
          flex-direction: column;
          
          .footer-top-content-right-contact-us-title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 187.5% */
            padding-top: 30px;
          }
          
          .footer-top-content-right-contact-us-des {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }
    }
  }
  
  .footer-bottom-container {
    height: 34px;
    padding: 5px 0 5px 0;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #60A9A6;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 400;
  }
}
