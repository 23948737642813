.product-item-container {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  
  .product-item-img-container {
    width: 250px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 100%;
      max-height: 100%;
      width: 250px;
      height: 200px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  
  .product-item-price {
    color: #DA5864;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    height: 26px;
    max-width: 100%;
    
    span {
      font-size: 20px;
    }
  }
  
  .product-item-des {
    color: #393939;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 162.5% */
    height: 52px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .product-item-btn {
    height: 30px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #60A9A6;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    user-select: none;
  }
}
