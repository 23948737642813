.g-swiper-container {
  width: 100%;
  position: relative;
  
  .g-swiper-swiper {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  
  .g-swiper-next-container,
  .g-swiper-prev-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  .g-swiper-prev-container {
    left: -36px;
  }
  .g-swiper-next-container {
    right: -36px;
  }
}
