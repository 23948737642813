.shopping-cart-container {
  width: 100%;
  padding: 50px 100px 150px;
  display: flex;
  
  .shopping-cart-left-container {
    width: 600px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    
    .shopping-cart-left-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 187.5% */
    }
    
    .shopping-cart-left-delivery-information-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      .shopping-cart-left-delivery-information-method-container {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        
        .shopping-cart-left-delivery-information-method-title {
          color: #393939;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          min-width: 120px;
        }
      }
      
      .shopping-cart-left-delivery-information-address-container {
        width: 100%;
        display: flex;
        gap: 20px;

        .shopping-cart-left-delivery-information-address-title {
          color: #393939;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          min-width: 120px;
        }
      }
    }
    
    .shopping-cart-left-delivery-payment-container {
      .shopping-cart-left-delivery-payment-type-container {
        display: flex;
        align-items: center;
        
        .shopping-cart-left-delivery-payment-type-title {
          display: flex;
          width: 130px;
          padding: 5px 0;
          align-items: flex-start;
          gap: 10px;
          flex-shrink: 0;
          color: #00606A;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          
          margin-right: 16px;
        }
        
        .shopping-cart-left-delivery-payment-type-group {
          .shopping-cart-left-delivery-payment-type-radio-container {
            display: inline-flex;
            align-items: center;
            
            span.ant-radio + span {
              display: flex;
              align-items: center;
              justify-content: center;
              
              img {
                max-width: 25px;
                max-height: 25px;
              }
            }
          }
        }
      }
      
      .shopping-cart-left-delivery-payment-form-container {
        margin-bottom: 20px;
        width: 100%;

        .shopping-cart-left-delivery-payment-form-row {
          display: flex;
          gap: 0 20px;

          .ant-form-item {
            flex: 1;
          }
        }

        .ant-form-item {
          margin-bottom: 20px;

          .ant-form-item-label {
            padding-bottom: 12px;

            label {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #666666;
            }

            .ant-form-item-required {
              &:after {
                content: '*';
                display: block;
                color: #EE1D52;
              }
            }
          }

          .ant-input {
            height: 45px;
            border: 1px solid rgba(102, 102, 102, 0.35);
            border-radius: 12px;
            font-size: 18px;
          }
        }
        
        .shopping-cart-left-delivery-payment-discount-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;

          > div,
          > .ant-input {
            width: 250px;
          }
        }

        .shopping-cart-left-delivery-payment-form-btn {
          display: flex;
          height: 40px;
          padding: 15px 40px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 20px;
          background: #DA5864;

          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
          user-select: none;
          
          align-self: center;
        }
      }

      .shopping-cart-left-delivery-payment-divider {
        padding: 20px 10px;
        display: flex;
        align-items: center;

        &:before,
        &:after {
          content: ' ';
          flex: 1;
          height: 2px;
          background-color: rgba(102, 102, 102, 0.25);
        }
      }
    }
  }
  
  .shopping-cart-right-container {
    width: 640px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #A1A1A1;
    margin: 10px 20px;
    
    .shopping-cart-right-title {
      display: flex;
      height: 48px;
      flex-direction: column;
      justify-content: center;

      color: #0A0D13;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
      margin-bottom: 8px;
    }
    
    .shopping-cart-right-line {
      width: 100%;
      display: flex;
      
      &:after {
        content: ' ';
        width: 100%;
        height: 1px;
        background: #ACACAC;
      }
    }
    
    .shopping-cart-right-product-list-container {
      padding: 36px 0;
      display: flex;
      flex-direction: column;
      gap: 36px;
      
      .shopping-cart-right-product-list-item-container {
        height: 140px;
        display: flex;
        gap: 20px;
        
        .shopping-cart-right-product-list-item-left-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          
          .shopping-cart-right-product-list-item-left-img-container {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          
          .shopping-cart-right-product-list-item-left-qty-container {
            display: flex;
            align-items: center;
            gap: 10px;
            
            .shopping-cart-right-product-list-item-left-qty-title {
              color: #393939;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 162.5% */
            }
            
            .shopping-cart-right-product-list-item-left-qty-input {
              width: 75px;
              height: 25px;
              border: 1px solid #C8C8C8;
              display: flex;
              align-items: center;
              border-radius: 0;

              .ant-input-number-input-wrap {
                height: 100%;

                .ant-input-number-input {
                  height: 100%;
                }
              }
            }
          }
        }
        
        .shopping-cart-right-product-list-item-right-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          
          .shopping-cart-right-product-list-item-right-info-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            
            .shopping-cart-right-product-list-item-right-info-title {
              color: #393939;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            
            .shopping-cart-right-product-list-item-right-info-price {
              color: #393939;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px; /* 110% */
              
              span {
                font-size: 14px;
                font-weight: 400;
              }
            }
            
            .shopping-cart-right-product-list-item-right-info-size {
              color: #393939;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          
          .shopping-cart-right-product-list-item-right-btn {
            color: #DA5864;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 185.714% */
            text-decoration-line: underline;
            cursor: pointer;
            user-select: none;
            width: max-content;
          }
        }
      }
    }
    
    .shopping-cart-right-order-info-container {
      width: 100%;
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 80px;
      
      .shopping-cart-right-order-info-line {
        padding: 20px 0 8px;
      }
      
      .shopping-cart-right-order-info-item-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .shopping-cart-right-order-info-item-title {
          color: #0A0D13;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        
        .shopping-cart-right-order-info-item-val {
          color: #0A0D13;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
    
    .shopping-cart-right-btn {
      display: flex;
      height: 50px;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 20px;
      background: #60A9A6;

      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      user-select: none;
    }
  }
}
