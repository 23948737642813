.header-container {
  width: 1440px;
  height: 118px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .header-left-detail-page-container {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    user-select: none;
    
    .header-left-detail-page-icon {
      color: #393939;
      font-size: 20px;
    }
    
    .header-left-detail-page-title {
      color: #393939;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 130% */
    }
  }
  
  .header-left-container {
    height: 78px;
    cursor: pointer;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  
  .header-right-container {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-right: 20px;
    
    .header-right-zipcode-container {
      width: 175px;
      height: 45px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 0 24px;
      border-radius: 12px;
      border: 1px solid rgba(102, 102, 102, 0.35);
      
      .header-right-zipcode-title {
        color: #062101;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      
      .header-right-zipcode-val {
        color: #393939;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    
    .header-right-menu-item-container {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      cursor: pointer;
      user-select: none;
      text-transform: uppercase;
    }
    
    .header-right-menu-item-active-container {
      font-weight: 700;
    }
  }
}
