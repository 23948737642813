.news-container {
  width: 100%;
  padding: 0 100px;
  
  .news-header-container {
    .content-header-title {
      padding: 0 235px;
    }
  }

  .news-content-container {
    padding: 30px 0 100px;

    .news-content-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 100px 80px;
      margin-bottom: 100px;

      .news-content-item-container {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .news-content-btn {
      color: #DA5864;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 130% */
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
  }
}
