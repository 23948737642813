.product-detail-container {
  width: 100%;
  padding: 0 100px;
  
  .product-detail-top-container {
    padding: 60px 0;
    
    .product-detail-top-title {
      display: flex;
      flex-direction: column;
      gap: 9px;
      margin-bottom: 35px;
      
      span {
        color: #5C8692;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 154.5%; /* 23.175px */
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
      
      div {
        color: #141414;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 58.5px */
        letter-spacing: -0.45px;
      }
    }
    
    .product-detail-top-info-container {
      display: flex;
      padding-top: 20px;
      gap: 35px;
      
      .product-detail-top-info-left-container {
        display: flex;
        width: 613px;
        height: 496px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .product-detail-top-info-left-product-img-swiper-container {
          width: 500px;
          
          .product-detail-top-info-left-product-img-swiper-swiper {
            width: 100%;

            .swiper-wrapper {
              height: 350px;
              margin-bottom: 37px;

              .product-detail-top-info-left-img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                img {
                  max-height: 100%;
                  max-width: 100%;;
                }
              }
            }

            .swiper-pagination {
              position: relative;
              padding: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .swiper-pagination-bullet {
                padding: 0;
                margin: 0;
                width: 12px;
                height: 12px;
              }

              .swiper-pagination-bullet-active {
                width: 16px;
                height: 16px;
                background-color: #7B61FF;
              }
            }
          }

          //.product-detail-top-info-left-product-img-swiper-prev {
          //  top: auto;
          //  bottom: 0;
          //}
          //
          //.product-detail-top-info-left-product-img-swiper-next {
          //  top: auto;
          //  bottom: 0;
          //}
        }
      }
      
      .product-detail-top-info-right-container {
        flex: 1;
        display: flex;
        padding: 30px 0;
        flex-direction: column;
        justify-content: center;
        gap: 26px;
        
        .product-detail-top-info-right-ref-no {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        
        .product-detail-top-info-right-price {
          color: #DA5864;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px; /* 72.222% */
          
          span {
            font-size: 16px;
          }
        }
        
        .product-detail-top-info-right-product-description-container {
          .product-detail-top-info-right-product-description-title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 180%; /* 28.8px */
          }
          
          .product-detail-top-info-right-product-description-item {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 180%;
            display: flex;
            align-items: flex-start;
            
            //&:before {
            //  content: '·';
            //  font-weight: bold;
            //  display: flex;
            //  align-items: center;
            //  justify-content: center;
            //  width: 16px;
            //  min-width: 16px;
            //}
          }
        }
        
        .product-detail-top-info-right-upcs {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          
          span {
            font-weight: 400;
          }
        }
        
        .product-detail-top-info-right-bottom-container {
          display: flex;
          align-items: center;
          gap: 50px;
          
          .product-detail-top-info-right-bottom-left-container {
            display: flex;
            align-items: center;
            gap: 10px;
            
            .product-detail-top-info-right-bottom-left-label {
              color: #393939;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px; /* 162.5% */
            }
            
            .product-detail-top-info-right-bottom-left-input {
              width: 75px;
              height: 25px;
              border: 1px solid #C8C8C8;
              display: flex;
              align-items: center;
              border-radius: 0;
              
              .ant-input-number-input-wrap {
                height: 100%;
                
                .ant-input-number-input {
                  height: 100%;
                }
              }
            }
          }
          
          .product-detail-top-info-right-bottom-btn {
            height: 30px;
            padding: 10px 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            background: #60A9A6;

            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
  .product-detail-rich-text-description-container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 50px 0 200px;
    .product-detail-rich-text-description-title {
      text-align: center;
      color: #60a9a6;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
    .product-detail-rich-text-description-content {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .product-detail-more-product-container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 50px 0 200px;
    
    .product-detail-more-product-title {
      color: #60A9A6;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }

    .product-detail-more-product-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 50px 80px;

      .product-detail-more-product-item-container {
        width: 250px;
      }
    }
  }
}
