.recipe-container {
  width: 100%;
  padding: 0 100px;
  
  .recipe-header-container {
    .content-header-title {
      padding: 0 179px;
    }
  }

  .recipe-content-container {
    padding: 30px 0 100px;

    .recipe-content-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 110px 0;
      margin-bottom: 80px;

      .recipe-content-item-container {
        display: flex;
        width: 607px;
        height: 186px;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        border: 1px solid rgba(141, 141, 141, 0.20);
        
        .recipe-content-item-img-container {
          width: 170px;
          height: 100%;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 40px;
          
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        
        .recipe-content-item-info-container {
          flex: 1;
          
          .recipe-content-item-info-title {
            color: #393939;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; /* 130% */
            text-transform: capitalize;
            margin-bottom: 10px;
          }
          
          .recipe-content-item-info-des {
            color: #393939;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 162.5% */
            text-transform: capitalize;
          }
        }
      }
    }

    .recipe-content-btn {
      color: #DA5864;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 130% */
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
  }
}
