.career-detail-container {
  width: 100%;
  padding: 0 100px;
  
  .career-detail-header-container {
    .content-header-title {
      padding: 0 180px;
    }
  }
  
  .career-detail-content-container {
    padding: 0 50px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    .career-detail-content-title {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 180%; /* 28.8px */
    }
    
    .career-detail-content-des {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
  }

  .career-detail-related-blogs-container {
    padding: 30px 0 100px;
    display: flex;
    flex-direction: column;
    gap: 80px;

    .career-detail-related-blogs-title {
      color: #60A9A6;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }

    .career-detail-related-blogs-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 100px 80px;

      .career-detail-related-blogs-item-container {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.career-detail-apply-now-modal {
  width: 795px !important;
  
  .ant-modal-content {
    padding: 100px;
    
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      
      .career-detail-apply-now-title {
        color: #60A9A6;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        padding: 0 52px;
      }
      
      .career-detail-apply-now-form-container {
        width: 100%;
        
        .ant-form-item {
          margin-bottom: 40px;
          
          &:last-child {
            margin-bottom: 0;
          }
          
          .ant-form-item-row {
            .ant-form-item-label {
              label {
                color: #666;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .ant-form-item-required {
                &:after {
                  content: '*';
                  display: block;
                  color: #ee1d52;
                }
              }
            }
            
            .career-detail-apply-now-form-input {
              height: 45px;
            }
            
            .career-detail-apply-now-form-textarea {
              height: 146px;
            }
          }
        }
      }
      
      .career-detail-apply-now-tip-container {
        color: #393939;
        font-feature-settings: 'salt' on;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */

        .career-detail-apply-now-tip-link {
          color: #60A9A6;
          font-weight: 700;
          text-decoration-line: underline;
          cursor: pointer;
          user-select: none;
        }
      }
      
      .career-detail-apply-now-btn {
        display: flex;
        height: 50px;
        padding: 15px 40px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 20px;
        background: #60A9A6;

        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
