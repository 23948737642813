.content-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  
  .content-header-des {
    color: #5C8692;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 154.5%; /* 23.175px */
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  
  .content-header-title {
    color: #141414;
    text-align: center;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 58.5px */
    letter-spacing: -0.45px;
  }
  
  .content-header-banner-container {
    width: 1240px;
    height: 700px;
    padding: 50px 100px;
    
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 20px;
    }
  }
  
  .content-header-banner-list-container {
    width: 1240px;
    height: 700px;
    padding: 50px 100px;
    
    .content-header-banner-list-swiper {
      width: 100%;
      height: 100%;
    }
    
    .content-header-banner-list-item-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
    
    .content-header-banner-list-prev {
      transform: scale(2) translateX(-50%);
    }
    .content-header-banner-list-next {
      transform: scale(2) translateX(50%);
    }
  }
  
  .content-header-btn {
    display: flex;
    width: 387px;
    height: 50px;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #60A9A6;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
